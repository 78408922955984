import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Text } from '../Text';

import { Link } from '../Link';

class Component extends React.Component {
  static displayName = 'Brand';

  static propTypes = {
    theme: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    energy: PropTypes.object.isRequired,
    audio: PropTypes.object.isRequired,
    sounds: PropTypes.object.isRequired,
    className: PropTypes.any,
    link: PropTypes.string,
    hover: PropTypes.bool,
    stableTime: PropTypes.bool,
    onEnter: PropTypes.func,
    onExit: PropTypes.func,
    onLinkStart: PropTypes.func,
    onLinkEnd: PropTypes.func
  };

  static defaultProps = {
    link: '/'
  };

  constructor () {
    super(...arguments);

    const { energy, stableTime } = this.props;

    if (!stableTime) {
      energy.updateDuration({ enter: 820 });
    }
  }

  componentWillUnmount () {
  }

  enter () {
    const { sounds } = this.props;
    sounds.logo.play();
  }

  exit () {
    const { sounds } = this.props;

    sounds.fade.play();
  }

  render () {
    const {
      theme,
      classes,
      energy,
      audio,
      sounds,
      className,
      link,
      hover,
      stableTime,
      onEnter,
      onExit,
      onLinkStart,
      onLinkEnd,
      ...etc
    } = this.props;

    return (
      <h1 style={{ textAlign: 'center', fontSize: '2rem' }} className={cx(classes.root, hover && classes.hover, className)} {...etc}>
        <Link
          className={classes.link}
          href={link}
          title='Cryptoodles'
          onLinkStart={onLinkStart}
          onLinkEnd={onLinkEnd}
          onMouseEnter={() => sounds.hover.play()}
        >
          <Text>
            Cryptoodles
          </Text>
        </Link>
      </h1>
    );
  }
}

export { Component };
