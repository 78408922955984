// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bag-js": () => import("./../../../src/pages/bag.js" /* webpackChunkName: "component---src-pages-bag-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-music-js": () => import("./../../../src/pages/music.js" /* webpackChunkName: "component---src-pages-music-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-todl-js": () => import("./../../../src/pages/todl.js" /* webpackChunkName: "component---src-pages-todl-js" */),
  "component---src-pages-tvm-js": () => import("./../../../src/pages/tvm.js" /* webpackChunkName: "component---src-pages-tvm-js" */)
}

